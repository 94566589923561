











































































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import FireStoreMixin from "@/mixins/firestoreMixin";
import { PatientInfo, DefaultReceiptSearchCondition } from "#/model/receipt";
import { SameBuildingAggregation } from "@/views/reciept/types";
import { ProcessState, PreCheckResponse } from "@/views/reciept/types";
import { COLLECTION_RECEIPT_PRE_CHECK } from "@/const/envFireStore";
import { PreCheckState } from "@/views/reciept/types";
import InfiniteLoading from "vue-infinite-loading";
import { PreCheckSameBuildingHistory } from "#/model/prechecksamebuilding";
import { DataTableHeader } from "vuetify/types/index";

@Component
export default class PreCheck extends Mixins(
  AxiosMixin,
  UtilMixin,
  FireStoreMixin,
  InfiniteLoading
) {
  /** 無限ローディング */
  @Ref("infiniteLoading")
  private readonly infiniteLoading!: InfiniteLoading;

  /** 読み込みページ数 */
  private page = 1;

  private isOpenDialog = false;

  /** 一回で取得するデータ量 */
  private limit = 10;

  private histories: PreCheckSameBuildingHistory[] = []; //未読一覧

  private historyRecords: { [key: number]: PreCheckSameBuildingHistory } = {};

  /** テーブルのヘッダー */
  private header: DataTableHeader[] = [
    {
      text: "状態",
      value: "status_flag",
      align: "center",
      width: "120px",
      cellClass: "text-center",
    },
    {
      text: "処理対象年月",
      value: "yearmonth",
      align: "center",
      width: "",
      cellClass: "text-center",
    },
    {
      text: "処理日時",
      value: "date_processed",
      align: "center",
      width: "",
      cellClass: "text-center",
    },
    {
      text: "処理件数",
      value: "processed_count",
      align: "center",
      width: "",
      cellClass: "text-center",
    },
    {
      text: "対象件数",
      value: "total_count",
      align: "center",
      width: "",
      cellClass: "text-center",
    },
    {
      text: "処理結果",
      value: "action",
      align: "center",
      width: "",
      cellClass: "text-center",
    },
    {
      text: "中断から再開",
      value: "restart",
      align: "center",
      width: "",
      cellClass: "text-center",
    },
  ];

  /** 実行回数カウント（新しくタブを開いてからの実行回数） */
  private doneCount = 0;

  /** 処理合計 */
  private total = 0;

  /** 処理カウント */
  private count = 0;

  /** 処理状態 */
  private processState = ProcessState.UNPROCESS;

  /** タブ情報 */
  private tab = 0;

  /** 処理結果メッセージ */
  private message = "";

  private hint =
    "下記3点を満たすと処理の対象になります。\n・レセプト処理の利用者にチェック有り\n・登録または確定された実積（精神科訪問看護を含む）\n※請求履歴確定は含まない\n・訪問看護記録書Ⅰに同一建物を設定\n※詳細\n「訪問看護記録書Ⅰ＞お住まいの状況に関する項目＞住居環境＞施設名」に「マスタメンテメニュー＞連携機関マスタ＞同一建物」を設定";

  /** 事前チェック結果 */
  private checkResponses: PreCheckResponse[] = [];

  /** 選択された利用者一覧 */
  private selectPatients: PatientInfo[] = [];

  private unfilteredpatients: PatientInfo[] = [];

  /** firestoreに保存する条件 */
  private condition = DefaultReceiptSearchCondition();

  /** 表示用の変数 */
  private targetYearmonth = "";
  private totalCount = 0;
  private selectedCount = 0;
  private unfilteredCount = 0;

  /** リアルタイムアップデート用リスナーをデタッチする関数 */
  private detach = () => {
    return;
  };

  private statusText(n: number): string {
    switch (n) {
      case 0:
        return "処理中";
      case 1:
        return "処理中";
      case 2:
        return "完了";
      case 3:
        return "中断";
      default:
        return "異常";
    }
  }

  created() {
    // 前画面でセットされた利用者と検索条件を取得
    this.isOpenDialog = true;

    const lsPatients = localStorage.getItem("PreCheckSelectPatients");
    const lsUfPatients = localStorage.getItem("PreCheckUnfilterPatients");
    const lsCondition = localStorage.getItem("PreCheckCondition");

    if (lsPatients !== null) {
      this.selectPatients = JSON.parse(lsPatients);
      this.selectedCount = this.selectPatients.filter(
        (sp: PatientInfo) => !sp.invoice_history
      ).length;
    }

    if (lsUfPatients !== null) {
      this.unfilteredpatients = JSON.parse(lsUfPatients);
      this.unfilteredCount = this.unfilteredpatients.filter(
        (sp: PatientInfo) => !sp.invoice_history
      ).length;
    }

    if (lsCondition !== null) {
      this.condition = JSON.parse(lsCondition);
      this.targetYearmonth = this.condition.search_cond.yearmonth;
      this.totalCount = this.condition.ids.length;
    }

    localStorage.removeItem("PreCheckSelectPatients");
    localStorage.removeItem("PreCheckCondition");
    this.collection = COLLECTION_RECEIPT_PRE_CHECK;
  }
  mounted() {
    // リアルタイムアップデート用リスナーを作成
    this.createListener();
  }

  beforeDestroy() {
    // リアルタイムアップデート用リスナーをデタッチ
    this.detach();
  }
  /** リアルタイムアップデート用リスナーの作成 */
  private createListener() {
    const docId = this.createDocumentId(this.condition.search_cond.office_id);
    this.detach = this.getListner(
      (query) => {
        return query.where("id", "==", docId);
      },
      (docChange) => {
        const stateData = docChange.doc.data() as PreCheckState;
        stateData.id = docChange.doc.id;
        if (stateData.status_flag == 1) {
          // 処理中
          this.processState = ProcessState.PROCESSING;
        } else if (this.doneCount != 0 && stateData.url != "") {
          // 処理完了
          this.processState = ProcessState.DISABLED;
          this.page = 0;
          this.getPreCheckHistory();
        }
        this.count = stateData.number_processe;
        this.total = stateData.total_count;
      }
    );
  }

  private createDocumentId(officeId: number) {
    return String(officeId);
  }

  /** レセプト事前チェックの履歴を取得 */
  private getPreCheckHistory() {
    this.postJsonCheck(
      window.base_url + "/api/receipt/preCheckSameBuilding/history",
      {
        page: this.page,
        office_id: this.condition.search_cond.office_id,
      },
      (res) => {
        if (res.data.histories != null) {
          // 重複を除外している
          res.data.histories.forEach((history: PreCheckSameBuildingHistory) => {
            this.historyRecords[history.id] = history;
          });
          this.histories = Object.values(this.historyRecords);
          this.histories.sort((a, b) => b.id - a.id);

          if (res.data.histories.length < 10) {
            //これ以上読み込まない
            this.infiniteLoading.stateChanger.complete();
          }
          // まだ読み込む
          this.page += 1;
          this.infiniteLoading.stateChanger.loaded();
        } else {
          this.infiniteLoading.stateChanger.complete();
        }
      }
    );
  }

  /** 「処理開始」ボタン無効化 */
  private get StartButtonDisabled(): boolean {
    return [
      ProcessState.PROCESSING,
      ProcessState.INTERRUPTING,
      ProcessState.DISABLED,
    ].includes(this.processState);
  }

  /** 「処理中断」ボタン無効化 */
  private get InterruptButtonDisabled(): boolean {
    return [
      ProcessState.UNPROCESS,
      ProcessState.PROCESSED,
      ProcessState.INTERRUPTING,
      ProcessState.INTERRUPTED,
      ProcessState.DISABLED,
    ].includes(this.processState);
  }

  /** 処理を中断する */
  private interruption() {
    this.processState = ProcessState.INTERRUPTING;
    // 中断のAPIを呼び出す
    this.postJsonCheck(
      window.base_url + "/api/receipt/aggregateSameBuildingAll/stop",
      {
        yearmonth: this.condition.search_cond.yearmonth,
        office_id: this.condition.search_cond.office_id,
      },
      (res) => {
        // todo 後で処理入れる
      }
    );
  }

  /** ダウンロードする */
  private downloadAll(item: any) {
    this.postJsonCheck(
      window.base_url + "/api/receipt/downloadPreCheckResultAll",
      {
        path: item.excel_path,
      },
      (res) => {
        location.href = res.data.path;
      }
    );
  }

  /** 「処理中」に設定 */
  public setProcessing() {
    //this.reset();
    this.processState = ProcessState.PROCESSING;
  }

  /** 「処理完了」に設定 */
  public setProcessed() {
    this.processState = ProcessState.PROCESSED;
  }

  /** 「中断完了」に設定 */
  public setInterrupted() {
    this.processState = ProcessState.INTERRUPTED;
  }

  /** 処理開始 */
  private async startProcess(id: number) {
    // ダイアログを出す
    if (
      !(await this.$openConfirm(
        "医療保険における同一建物居住者に係る算定を自動算定します。よろしいですか？"
      ))
    ) {
      return;
    }

    // 請求履歴未確定の利用者のみ取得
    const patients: PatientInfo[] = this.selectPatients.filter(
      (sp: PatientInfo) => !sp.invoice_history
    );

    // 対象利用者がいない場合は何もしない
    if (patients.length === 0) {
      await this.$openAlert("処理対象がありません。");
      return;
    }

    // 「処理中」に変更
    this.setProcessing();

    // 対象年月が指定されない場合は何もしない(リロードなど)
    if (this.condition.search_cond.yearmonth === null) {
      await this.$openAlert("事前チェック対象年月を前画面で選択してください。");
      return;
    }

    this.doneCount++;
    this.postJsonBackgroundIgnore(
      window.base_url + "/api/receipt/aggregateSameBuildingAll",
      {
        yearmonth: this.condition.search_cond.yearmonth,
        office_id: this.condition.search_cond.office_id,
        patients: patients,
        id: id,
      },
      (res) => {
        //
      }
    );
  }

  /** Excelダウンロードパスが正しいかどうか*/
  private isInvalidLink(path: string) {
    // 現時点ではURLの形式をとらなくしたので、変える必要がある
    const regex = /^\d+\/excel\/\d{4}-\d{2}\/.*\.xlsx$/;
    return !regex.test(path);
  }

  private closeWindow() {
    window.close();
  }

  private get BgColor() {
    return "rgb(251,245,229) !important"; // 常に指定した色にする
  }

  private get Color() {
    return "warning"; // 常にwarning色にする
  }

  private getColorByStatus(statusFlag: number) {
    switch (statusFlag) {
      case 1:
        return "success-outlined";
      case 2:
        return "success";
      case 3:
        return "error";
      default:
        return "error";
    }
  }
}
