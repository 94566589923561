
























































































































































































































































































































































































































































































import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import DateMixin from "#/mixins/dateMixin";
import { Choice, VForm } from "@/types";
import {
  DefaultSearchCondition,
  PatientInfo,
  UntreatedInfo,
  ReceiptSearchCondition,
  DefaultReceiptSearchCondition
} from "#/model/receipt";
import { ElemDataTable } from "#/types/table";
import SearchPanel from "#/components/SearchPanel.vue";
import OfficeSelect from "@/components/common_ibow/OfficeSelect.vue";
import {
  ChoiceCompareNumber,
  ChoiceCompareDate
} from "#/components/selectFilter/SelectFilter.vue";
import { COLLECTION_RECEIPT_LIST_ITEMS } from "@/const/envFireStore";
import FireStoreMixin from "@/mixins/firestoreMixin";
import ResultDialog from "@/components/receipt/resultDialog.vue";
import RightSideMenu from "@/views/reciept/RightSideMenu.vue";
import PreCheckButton from "./precheck/PreCheckButton.vue";
import {
  OfficeHistory,
  DefaultOfficeHistory,
  DefaultOfficeHistoryOverTherapistVisitCount,
  DefaultOfficeHistoryOverTherapistTargetAddition,
  NextMonthGensanSetting,
  DefaultNextMonthGensanSetting
} from "#/model/agreement";

@Component({
  components: {
    OfficeSelect,
    SearchPanel,
    ResultDialog,
    RightSideMenu,
    PreCheckButton,
  },
})
export default class Reciept extends Mixins(
  AxiosMixin,
  UtilMixin,
  FireStoreMixin,
  DateMixin
) {
  /** 入力フォームRef */
  @Ref("receipt") private readonly form!: VForm;

  /** データテーブルRef */
  @Ref("receiptListTable") private readonly receiptListTable!: ElemDataTable;

  /** レセプト処理画面ダイアログ */
  @Ref("result-dialog") private readonly resultDialog!: ResultDialog;

  @Ref("preCheckButton") private readonly preCheckButton!: PreCheckButton;

  /** 表示\n1:請求する\n2:請求しない */
  private dispMode = 1;

  /** 検索押下時のyearmonth */
  private searchYearmonth = "";

  /** 検索押下時のofficeId */
  private searchOfficeId = 0;

  private isOpenDialog = false;

  /** タブ */
  private tab = 0;

  /** レセプトURL */
  private ibowReceiptUrl = "";

  /** iBowレセプト契約 */
  private isReceiptUse = false;

  /** レセプト事前チェック機能使用フラグ */
  private useReceiptPreCheck = false;

  /** レスポンスのままフィルタリングしていない利用者一覧 */
  private unfilterPatients: PatientInfo[] = [];

  /** 選択された利用者一覧 */
  private selectPatients: PatientInfo[] = [];

  /** 計画単位数スナックバー表示フラグ */
  private planPointSnackbar = false;

  /** 未処理情報 */
  private untreatedInfos: UntreatedInfo[] = [];

  /** 未処理総件数 */
  private sumUntreated = 0;

  /** 翌月の事業所設定（訪問回数超過減算で使用） */
  private nextMonthOfficeHistory: OfficeHistory = DefaultOfficeHistory();

  /** 翌月の事業所設定の反映確認ダイアログ */
  protected isOpenGensanApplyDialog = false;
  private nextMonthGensanSetting: NextMonthGensanSetting = DefaultNextMonthGensanSetting();

  /** 検索サイドバー表示フラグ */
  private searchPanel = false;

  private isContinue = false;

  /** firestoreに保存する条件 */
  private condition = DefaultReceiptSearchCondition();

  /** 文字列検索方法の選択肢 */
  private choiceTextMatchBy: Choice[] = [
    { value: 0, text: "前方一致" },
    { value: 1, text: "部分一致" }
  ];

  private nextPage() {
    this.isContinue = true;
    this.isOpenDialog = false;
  }

  /** 数値検索方法の選択肢 */
  private choiceNumberCompareBy: Choice[] = ChoiceCompareNumber();

  /** 日付検索方法の選択肢 */
  private choiceDateCompareBy: Choice[] = ChoiceCompareDate();

  //データテーブル
  private headers: DataTableHeader[] = [
    {
      text: "利用者",
      value: "name",
      width: "9.2rem",
      class: "pl-0",
      cellClass: "pl-0 text-pre-wrap"
    },
    {
      text: "ふりがな",
      value: "furigana",
      width: "9rem",
      class: "pl-0",
      cellClass: "pl-0"
    },
    {
      text: "",
      value: "select",
      align: "start",
      width: "60px",
      class: "pl-0",
      cellClass: "pl-0",
      sortable: false
    },
    {
      text: "保険",
      value: "visit_type",
      width: "8.5rem",
      class: "pl-0",
      cellClass: "pl-0"
    },
    {
      text: "計画単位数",
      value: "plan_point",
      width: "7.5rem",
      class: "pl-0",
      cellClass: "pl-0"
    },
    {
      text: "請求履歴",
      value: "invoice_history",
      width: "5.8rem",
      class: "pl-0",
      cellClass: "pl-0"
    },
    {
      text: "実績確定日",
      value: "fixed_date",
      width: "6.3rem",
      class: "pl-0",
      cellClass: "pl-0"
    },
    {
      text: "確定状況",
      value: "status",
      width: "5.8rem",
      class: "pl-0",
      cellClass: "pl-0"
    },
    {
      text: "備考",
      value: "other",
      width: "180px",
      class: "pl-0",
      cellClass: "pl-0"
    },
    {
      text: "利用者ID",
      value: "display_id",
      width: "calc(7.5rem - 16px)",
      class: "pl-0",
      cellClass: "pl-0"
    }
  ];

  @Watch("condition", { deep: true }) watchCondition() {
    this.documentSave(this.condition);
  }

  @Watch("FilterPatients") watchFilterPatients() {
    this.condition.ids = this.FilterPatients.map(patient => {
      return Number(patient.patient_id);
    });
  }

  private closeWindow() {
    this.isOpenDialog = false;
  }

  private async openDialog() {
    this.isOpenDialog = true;

    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (!this.isOpenDialog) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  }

  /** フィルターされた利用者 */
  private get FilterPatients() {
    return this.unfilterPatients
      .filter(p => {
        // 利用者名・ふりがな絞り込み
        if (!this.condition.search_cond.patient_name) {
          return true;
        }
        return (
          this.matchText(
            p.name.replace(/\s/g, ""),
            this.condition.search_cond.patient_name.replace(/\s/g, ""),
            this.condition.search_cond.patient_name_by
          ) ||
          this.matchText(
            p.furigana.replace(/\s/g, ""),
            this.condition.search_cond.patient_name.replace(/\s/g, ""),
            this.condition.search_cond.patient_name_by
          )
        );
      })
      .filter(p => {
        // 利用者ID絞り込み
        if (!this.condition.search_cond.display_id) return true;
        return this.matchText(
          p.display_id,
          this.condition.search_cond.display_id,
          this.condition.search_cond.display_id_by
        );
      })
      .filter(p => {
        // 医療絞り込み
        if (!this.condition.search_cond.is_medical) return true;
        return p.medical !== "";
      })
      .filter(p => {
        // 介護絞り込み
        if (!this.condition.search_cond.is_nursing) return true;
        return p.care !== "";
      })
      .filter(p => {
        // 予防絞り込み
        if (!this.condition.search_cond.is_nursing_pre) return true;
        return p.prevention !== "";
      })
      .filter(p => {
        // 定期絞り込み
        if (!this.condition.search_cond.is_regular) return true;
        return p.regular !== "";
      })
      .filter(p => {
        // 計画単位数絞り込み
        if (!this.condition.search_cond.plan_point) return true;
        return this.compareNumber(
          Number(p.plan_point),
          Number(this.condition.search_cond.plan_point),
          this.condition.search_cond.plan_point_by
        );
      })
      .filter(p => {
        // 請求履歴確定済み絞り込み
        if (!this.condition.search_cond.is_billing) return true;
        if (this.condition.search_cond.is_billing)
          return p.invoice_history != "";
      })
      .filter(p => {
        // 確定日
        if (!this.condition.search_cond.decision_date) return true;
        return this.compareDate(
          p.fixed_date,
          this.condition.search_cond.decision_date,
          this.condition.search_cond.decision_date_by
        );
      })
      .filter(p => {
        // 実績確定済み絞り込み
        if (!this.condition.search_cond.status) return true;
        return p.status == "確定済";
      })
      .filter(p => {
        // 計画単位数が基準内単位数を下回るデータのみ
        if (!this.condition.search_cond.is_plan_above) return true;
        return (
          p.org_plan_point !== "" &&
          Number(p.org_plan_point) < Number(p.receipt.base_inner_point)
        );
      })
      .map(p => {
        p.fixed_date = p.fixed_date.replaceAll("-", "/");
        return p;
      });
  }

  /** 選択された未確定一覧 */
  private get FilterResultConfirmedPatients() {
    const patients: PatientInfo[] = [];
    for (const selectPatient of this.selectPatients) {
      if (!selectPatient.fixed_date && !selectPatient.invoice_history) {
        patients.push(selectPatient);
      }
    }
    return patients;
  }

  /** 選択された確定かつ請求履歴未確定一覧 */
  private get FilterReleaseConfirmedPatients() {
    const patients: PatientInfo[] = [];
    for (const selectPatient of this.selectPatients) {
      if (selectPatient.fixed_date && !selectPatient.invoice_history) {
        patients.push(selectPatient);
      }
    }
    return patients;
  }

  /** 選択された請求履歴確定一覧 */
  private get FilterReleaseBillingPatients() {
    const patients: PatientInfo[] = [];
    for (const selectPatient of this.selectPatients) {
      if (selectPatient.invoice_history) {
        patients.push(selectPatient);
      }
    }
    return patients;
  }

  // 絞り込みしているかどうか
  private get IsFiltered(): boolean {
    return !(
      this.condition.search_cond.patient_name === "" &&
      this.condition.search_cond.patient_name_by === 1 &&
      this.condition.search_cond.display_id === "" &&
      this.condition.search_cond.display_id_by === 0 &&
      !this.condition.search_cond.is_medical &&
      !this.condition.search_cond.is_nursing &&
      !this.condition.search_cond.is_nursing_pre &&
      !this.condition.search_cond.is_regular &&
      !this.condition.search_cond.plan_point &&
      this.condition.search_cond.plan_point_by === 0 &&
      !this.condition.search_cond.is_billing &&
      !this.condition.search_cond.decision_date &&
      this.condition.search_cond.decision_date_by === 0 &&
      !this.condition.search_cond.status &&
      !this.condition.search_cond.is_plan_above
    );
  }

  private async created() {
    //firestoreから取得
    this.collection = COLLECTION_RECEIPT_LIST_ITEMS;
    this.documentId = String(this.loginUser.id);
    const cond = await this.documentGet();
    if (cond) {
      this.condition = cond as ReceiptSearchCondition;
      if (!this.condition.search_cond) {
        //search_condが空の場合、埋める
        this.condition.search_cond = DefaultSearchCondition();
      }
    } else if (cond === false) {
      // firestore取得失敗
      this.$openAlert(
        "正しい画面情報が取得できませんでした。画面を更新して再度お試しください。"
      );
      return;
    }

    //ログインユーザー（職員）から取得
    if (!this.condition.search_cond.office_id) {
      this.condition.search_cond.office_id = this.loginUser.office_id;
      if (this.loginUser.office_id === 0) {
        this.condition.search_cond.office_id = this.HeadOffice;
      }
    }

    //クエリパラメータから取得
    if (typeof this.$route.query.officeid === "string") {
      this.condition.search_cond.office_id = Number(this.$route.query.officeid);
    }
    if (typeof this.$route.query.yearmonth === "string") {
      this.condition.search_cond.yearmonth = this.$route.query.yearmonth;
    } else {
      this.condition.search_cond.yearmonth = this.lastOrThisMonth();
    }

    this.search();
  }

  /** 取得 */
  private search() {
    //選択された利用者のリセット
    this.unfilterPatients = [];
    this.selectPatients = [];
    this.searchYearmonth = this.condition.search_cond.yearmonth;
    this.searchOfficeId = this.condition.search_cond.office_id;
    //請求
    if (this.dispMode == 1) {
      this.postJsonCheck(
        window.base_url + "/api/receipt/patients/search",
        {
          yearmonth: this.condition.search_cond.yearmonth,
          office_id: this.condition.search_cond.office_id,
          tab: this.tab
        },
        res => {
          this.unfilterPatients = res.data.patients;
          this.ibowReceiptUrl = res.data.ibow_receipt_url;
          this.isReceiptUse = res.data.is_receipt_use;
          this.untreatedInfos = res.data.untreated_infos;
          this.sumUntreated = res.data.sum_untreated;
          this.useReceiptPreCheck = res.data.use_receipt_pre_check;
        }
      );
    }
    //非請求
    if (this.dispMode == 2) {
      this.postJsonCheck(
        window.base_url + "/api/receipt/patients/searchUnsolicited",
        {
          yearmonth: this.condition.search_cond.yearmonth,
          office_id: this.condition.search_cond.office_id,
          tab: this.tab
        },
        res => {
          this.unfilterPatients = res.data.patients;
          this.isReceiptUse = res.data.is_receipt_use;
          this.ibowReceiptUrl = res.data.ibow_receipt_url;
          this.untreatedInfos = res.data.untreated_infos;
          this.sumUntreated = res.data.sum_untreated;
        }
      );
    }
    this.preCheckButton.detach();
    this.preCheckButton.setPreCheckState(this.searchOfficeId);
  }

  /** 検索条件のリセット */
  private setDefaultSearch() {
    const resetCond = DefaultSearchCondition();
    resetCond.office_id = this.condition.search_cond.office_id;
    resetCond.yearmonth = this.condition.search_cond.yearmonth;
    this.condition.search_cond = resetCond;
  }

  /** 未処理検索 */
  private changeYear(yearmonth: string) {
    this.condition.search_cond.yearmonth = yearmonth;
    this.search();
  }

  /** iBowレセプトに遷移 */
  private moveIbowReceipt() {
    const url = this.ibowReceiptUrl + "?fromIbow=1";
    window.open(url, "_blank");
  }

  /** 検索ボタンクリック */
  private async clickSearch() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }
    this.search();
  }

  /** 実績確定 */
  private async batchResultsConfirmeds() {
    //確認
    if (
      !(await this.$openConfirm(
        "一括確定を行います。データ量によっては数十分かかる場合があります。\n\n確定処理中に以下の状態になった場合は画面を更新してください。\n・画面がスリープモードになった場合\n・処理終了件数が長時間増えない場合"
      ))
    ) {
      return;
    }

    //実績確定対象がない場合
    if (this.FilterResultConfirmedPatients.length === 0) {
      await this.$openAlert("実績確定対象がありません。");
      return;
    }

    this.resultDialog.open(
      "実績確定中",
      "実績確定処理終了件数",
      this.FilterResultConfirmedPatients.length,
      true
    );
    let ok = true;
    for (const processingSelectPatient of this.FilterResultConfirmedPatients) {
      await this.executionBatchResultsConfirmeds(processingSelectPatient);
      if (this.resultDialog.getInterruptionFlg()) {
        this.resultDialog.setTitle("実績確定処理中断");
        this.resultDialog.finishInterruption();
        ok = false;
        break;
      }
    }
    if (ok) {
      this.resultDialog.setTitle("実績確定終了");

      // 実績確定時のチェックを行う
      if (this.searchYearmonth >= "2024-05"){
         this.checkMonthConfirmedResult();
      }
    }
  }

  /** 1件ごとの実績確定 */
  private executionBatchResultsConfirmeds(
    processingSelectPatient: PatientInfo
  ) {
    return new Promise(resolve => {
      this.postJsonBackground(
        window.base_url + "/api/receipt/batchResultsConfirmeds",
        {
          yearmonth: this.condition.search_cond.yearmonth,
          office_id: this.condition.search_cond.office_id,
          select_patient: processingSelectPatient
        },
        res => {
          this.resultDialog.updateResult(
            res.data.results_confirmeds_success_message.existence_flg,
            res.data.results_confirmeds_success_message.message + "\n",
            res.data.results_confirmeds_error_message.existence_flg,
            res.data.results_confirmeds_error_message.message + "\n"
          );
          resolve(true);
        },
        () => {
          this.resultDialog.updateResult(
            false,
            "" + "\n",
            true,
            processingSelectPatient.name +
              "\n" +
              "・予期せぬエラーが発生しました。" +
              "\n"
          );
          resolve(false);
        }
      );
    });
  }

  /** 実績確定解除 */
  private async batchReleaseConfirmedResults() {
    //確認
    if (
      !(await this.$openConfirm(
        "一括解除を行います。データ量によっては数十分かかる場合があります。"
      ))
    ) {
      return;
    }

    //実績確定解除対象がない場合
    if (this.FilterReleaseConfirmedPatients.length === 0) {
      await this.$openAlert("実績確定解除対象がありません。");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/receipt/batchReleaseConfirmedResults",
      {
        yearmonth: this.condition.search_cond.yearmonth,
        office_id: this.condition.search_cond.office_id,
        select_patients: this.FilterReleaseConfirmedPatients
      },
      res => {
        this.resultDialog.open(
          "実績確定解除終了",
          "実績確定解除処理終了件数",
          this.FilterReleaseConfirmedPatients.length,
          false
        );
        this.resultDialog.messageResult(
          res.data.release_confirmed_results_success_message.message +
            res.data.release_confirmed_results_error_message.message
        );
      }
    );
  }

  /** 請求履歴確定 */
  private async batchBillingHistoryFixeds() {
    //請求履歴確定対象がない場合
    if (this.FilterReleaseConfirmedPatients.length === 0) {
      await this.$openAlert("請求履歴確定対象がありません。");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/receipt/batchBillingHistoryFixeds",
      {
        yearmonth: this.condition.search_cond.yearmonth,
        office_id: this.condition.search_cond.office_id,
        select_patients: this.FilterReleaseConfirmedPatients
      },
      res => {
        this.resultDialog.open(
          "請求履歴確定終了",
          "請求履歴確定処理終了件数",
          this.FilterReleaseConfirmedPatients.length,
          false
        );
        this.resultDialog.messageResult(
          res.data.billing_history_fixeds_success_message.message +
            res.data.billing_history_fixeds_error_message.message
        );
      }
    );
  }

  /** 請求履歴確定解除 */
  private async batchReleaseBillingHistorys() {
    //請求履歴確定解除対象がない場合
    if (this.FilterReleaseBillingPatients.length === 0) {
      await this.$openAlert("請求履歴確定解除対象がありません。");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/receipt/batchReleaseBillingHistorys",
      {
        yearmonth: this.condition.search_cond.yearmonth,
        office_id: this.condition.search_cond.office_id,
        select_patients: this.FilterReleaseBillingPatients
      },
      res => {
        this.resultDialog.open(
          "請求履歴確定解除終了",
          "請求履歴確定解除処理終了件数",
          this.FilterReleaseBillingPatients.length,
          false
        );
        this.resultDialog.messageResult(
          res.data.release_billing_historys_success_message.message +
            res.data.release_billing_historys_error_message.message
        );
      }
    );
  }

  // 非請求
  private notBilling() {
    //確認
    if (
      !confirm(
        "実績確定されているデータは対象外です。" +
          "\n" +
          "対象データを非請求にします。よろしいですか？"
      )
    ) {
      return;
    }

    //非請求対象がない場合
    if (this.FilterResultConfirmedPatients.length == 0) {
      alert("非請求対象がありません。");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/receipt/notBilling",
      {
        yearmonth: this.searchYearmonth,
        select_patients: this.FilterResultConfirmedPatients,
        office_id: this.searchOfficeId,
        tab: this.tab
      },
      () => {
        alert("処理が完了しました。");
        this.search();
      }
    );
  }

  // 請求対象に戻す
  private billing() {
    //確認
    if (!confirm("対象データを請求対象に戻します。よろしいですか？")) {
      return;
    }

    //請求対象に戻す対象がない場合
    if (this.selectPatients.length == 0) {
      alert("請求対象に戻す対象がありません。");
      return;
    }

    this.postJsonCheck(
      window.base_url + "/api/receipt/billing",
      {
        select_patients: this.selectPatients,
        office_id: this.searchOfficeId
      },
      () => {
        alert("処理が完了しました。");
        this.search();
      }
    );
  }

  //計画単位数保存
  private async savePlanPoint(patient: PatientInfo) {
    if (patient.plan_point === patient.org_plan_point) return;

    if (Number(patient.plan_point) > patient.limit_point) {
      if (
        !(await this.$openConfirm(
          "計画単位数が区分支給限度基準を超えていますがこのまま登録しますか？\n区分支給限度額基準:" +
            patient.limit_point +
            "単位\n※区分支給限度額基準は月に2つ以上の介護保険がある場合、最新の介護保険をもとに算出しています。"
        ))
      ) {
        //登録しない場合、元データに戻す
        patient.plan_point = patient.org_plan_point;
        return;
      }
    }

    this.postJsonCheck(
      window.base_url + "/api/receipt/savePlanPoints",
      {
        yearmonth: this.condition.search_cond.yearmonth,
        patient: patient
      },
      () => {
        if (patient.status != "" && patient.status != "未確定") {
          this.$openAlert(
            "計画単位数をサービス提供表に反映するためには、再度実績確定を行う必要があります。"
          );
        }
        this.planPointSnackbar = true;
        this.search();
      }
    );
  }

  /** レセプト事前チェック画面を開く */
  private async onClickPreCheck() {
    const res = await this.openDialog();
    if (!this.isContinue) return;

    // 別タブで利用する値なのでローカルストレージに保存
    localStorage.setItem(
      "PreCheckSelectPatients",
      JSON.stringify(this.selectPatients)
    );
    localStorage.setItem(
      "PreCheckUnfilterPatients",
      JSON.stringify(this.unfilterPatients)
    );
    localStorage.setItem("PreCheckCondition", JSON.stringify(this.condition));

    console.log("検索条件の一覧：");
    console.log(this.condition);

    const route = this.$router.resolve({
      name: "PreCheck",
    });
    window.open(route.href, "_blank");
    this.isContinue = false;
  }

  /** 対象月の実績確定時のチェック */
  private async checkMonthConfirmedResult() { 
    // 親事業所のIDを取得
    const parentInfo = this.masters.group_offices.find((row: any) => row.value == this.condition.search_cond.office_id);
    if (!parentInfo) return;
    const parentOfficeId = parentInfo.other;

    // API実行
    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/checkMonthConfirmedResult",
      {
        office_id: parentOfficeId,
        yearmonth: this.condition.search_cond.yearmonth,
        enable_check_over_therapist_target_additions: 1
      },         
      async res => {
        this.nextMonthOfficeHistory = res.data.next_month_office_history;
          // 訪問回数超過減算
          if (res.data.estimated_is_uncalculated_target_additions != res.data.conventional_is_uncalculated_target_additions) {
            this.confirmUpdateNextMonthOfficeHistory(this.condition.search_cond.yearmonth, res.data.conventional_is_uncalculated_target_additions, res.data.estimated_is_uncalculated_target_additions);
          }
      },
      err => {
        console.log(err)
      }
    );
  }

  // 翌月の事業所設定の値を変更するかの確認
  private async confirmUpdateNextMonthOfficeHistory(yearmonth: string, conventionalValue:number, estimatedValue: number) {
    // 設定値に変更が無ければスキップ
    if (conventionalValue == estimatedValue) return;
    // 対象月の翌月
    const targetNextYearmonth = new Date(yearmonth+"-01")
    targetNextYearmonth.setMonth(targetNextYearmonth.getMonth() + 1);
    const nextYearmonthNum = Number(this.dateToStr(targetNextYearmonth, "yyyyMM"));
    // 初期化
    this.nextMonthGensanSetting = DefaultNextMonthGensanSetting();
    this.nextMonthGensanSetting = {
      yearmonth: nextYearmonthNum,
      yearmonthText: this.dateToStr(targetNextYearmonth, "yyyy年M月"),
      conventionalValue: conventionalValue,
      conventionalValueText: conventionalValue == 0 ? "なし" : "あり",
      estimatedValue: estimatedValue,
      estimatedValueText: estimatedValue == 0 ? "なし" : "あり",
    }
    // 確認ポップアップの表示
    this.isOpenGensanApplyDialog = true;
  }
  
  //（超過減算）集計結果を翌月の事業所設定に適用する
  private applyOverTherapistTargetAdditions() {
    this.isOpenGensanApplyDialog = false;
    // 年月計算
    const year = Math.floor(this.nextMonthGensanSetting.yearmonth / 100);
    const month = this.nextMonthGensanSetting.yearmonth % 100;
    const fiscalYear = month < 4 ? year - 1 : year;
    // 翌月の事業所設定に超過減算の設定がなければ生成する
    this.generateOverTherapistSettingsForNextMonth(fiscalYear);
    // 更新対象の検索と更新
    for (const target_addition of this.nextMonthOfficeHistory.office_history_over_therapist_target_addition) {
      if (target_addition.yearmonth == this.nextMonthGensanSetting.yearmonth) {
        target_addition.is_uncalculated = this.nextMonthGensanSetting.estimatedValue;
      }
    }
    // 更新
    this.postJsonCheck(
      window.base_url + "/api/patient/receipt/saveOverTherapistTargetAdditions",
      {
        office_info: this.nextMonthOfficeHistory,
      },
      async (res: any) => {
        await this.$openAlert(`${this.nextMonthGensanSetting.yearmonthText}の減算は"${this.nextMonthGensanSetting.estimatedValueText}"です。\n\n事業所マスタ＞訪問回数超過等減算＞減算設定で確認できます。`)
      },
      (err: any) => {
        console.log(err)
      }
    );
  }

  //（超過減算）超過減算用の設定値を生成する
  private generateOverTherapistSettingsForNextMonth(fiscalYear: number) {
    // 既に追加されているyearであれば追加しない
    if (fiscalYear == 0 || this.existYearInOverTherapistVisitCount(fiscalYear)) return;
    // 年度の減算を追加する
    if (!this.nextMonthOfficeHistory.office_history_over_therapist_visit_count) this.nextMonthOfficeHistory.office_history_over_therapist_visit_count = [];
    const newTherapistVisitCount = DefaultOfficeHistoryOverTherapistVisitCount();
    newTherapistVisitCount.year = fiscalYear;
    // 減算データ作成時に従来の設定値をチェックする。
    if (fiscalYear == 2024 && this.nextMonthOfficeHistory.care_over_therapist_visit_count_flg == 1) newTherapistVisitCount.is_over = 1;
    this.nextMonthOfficeHistory.office_history_over_therapist_visit_count.push(newTherapistVisitCount);

    // 各月の減算の配列を追加する
    if (!this.nextMonthOfficeHistory.office_history_over_therapist_target_addition) this.nextMonthOfficeHistory.office_history_over_therapist_target_addition = [];
    const months = fiscalYear == 2024 ? [6, 7, 8, 9, 10, 11, 12, 1, 2, 3] : [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];   // 2024年は改定年度のため6月から開始
    months.forEach(month => {
      const newTargetAddition = DefaultOfficeHistoryOverTherapistTargetAddition();
      const newYearmonth = (month < 4 ? fiscalYear + 1 : fiscalYear ) * 100 + month; // ex)2024年4月なら202404
      newTargetAddition.yearmonth = newYearmonth;
      newTargetAddition.fiscal_year = fiscalYear;
      // 減算データ作成時に従来の設定値をチェックする。
      if ((newYearmonth == 202406 && this.nextMonthOfficeHistory.care_over_therapist_no_additions_flg == 1) || (newYearmonth == 202407 && this.nextMonthOfficeHistory.care_over_therapist_no_additions_july_flg == 1)) {
        newTargetAddition.is_uncalculated = 1;
      }
      this.nextMonthOfficeHistory.office_history_over_therapist_target_addition.push(newTargetAddition);
    });
  }

  //（超過減算）指定した年度が既に登録済みか否か
  private existYearInOverTherapistVisitCount(year: number) :boolean {
    if (!this.nextMonthOfficeHistory.office_history_over_therapist_visit_count) return false;
    return this.nextMonthOfficeHistory.office_history_over_therapist_visit_count.some((row: any) => row.year == year);
  }

  //選択（利用者レセプト画面に選択の対象月で遷移）
  public clickChoice(item: PatientInfo) {
    this.$router.push({
      name: "PatientRecieptDetail",
      query: { id: `${item.patient_id}`, yearmonth: this.searchYearmonth }
    });
  }

  /** 並び替え変更 */
  private changeSort(items: PatientInfo[]) {
    if (!items.length) return;
    this.condition.ids = items.map(patient => {
      return Number(patient.patient_id);
    });
  }

  /** データをグレーにする（請求履歴確定済のもの） ※データテーブルに渡す */
  private itemRowBackground(item: PatientInfo) {
    return item.invoice_history != "" ? "grey lighten-5" : "";
  }

  /** キーワード入力文字を検索条件に反映 ※データテーブルに渡す */
  private updateKeyword(newValue: string) {
    this.condition.search_cond.patient_name = newValue;
  }

  /** 事業所必須条件 */
  private requiredOffice(): boolean | string {
    if (this.condition.search_cond.office_id > 0) {
      return true;
    }
    return false;
  }

  /** 表示：請求・非請求 */
  @Watch("dispMode")
  private handleDispMode() {
    this.clickSearch();
  }

  /** タブの値変更 */
  @Watch("tab")
  private handleTab() {
    this.clickSearch();
  }

  /** 文字列一致を調べる */
  private matchText(
    subject: string,
    pattern: string,
    matchBy: number
  ): boolean {
    const matchIndex = subject.indexOf(pattern);
    switch (matchBy) {
      case 0: // 前方一致
        return matchIndex === 0;
      case 1: // 部分一致
        return matchIndex !== -1;
      default:
        return false;
    }
  }

  /** 数値を比較する */
  private compareNumber(
    target: number,
    base: number,
    compareBy: number
  ): boolean {
    switch (compareBy) {
      case 0: // に等しい
        return target === base;
      case 1: // に等しくない
        return target !== base;
      case 2: // より大きい
        return target > base;
      case 3: // 以上
        return target >= base;
      case 4: // より小さい
        return target < base;
      case 5: // 以下
        return target <= base;
      default:
        return false;
    }
  }

  /** 日付を比較する */
  private compareDate(
    target: string,
    base: string,
    compareBy: number
  ): boolean {
    const tTime = new Date(target.replaceAll("-", "/")).getTime();
    const bTime = new Date(base.replaceAll("-", "/")).getTime();
    if (Number.isNaN(tTime) || Number.isNaN(bTime)) {
      return false;
    }
    switch (compareBy) {
      case 0: // に等しい
        return tTime === bTime;
      case 1: // に等しくない
        return tTime !== bTime;
      case 2: // より後
        return tTime > bTime;
      case 3: // 以後
        return tTime >= bTime;
      case 4: // より前
        return tTime < bTime;
      case 5: // 以前
        return tTime <= bTime;
      default:
        return false;
    }
  }
}
